/* eslint-disable use-isnan */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useStore} from '../../../../../../store/store';
import {observer} from 'mobx-react';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import css from '../../../../../../styles/FacebookStatistics.module.scss';
import clsx from 'clsx';
import {toast} from 'react-toastify';
import moment from 'moment';
import {CreateAppModal} from '../../../../../../_metronic/partials';
import PageWrapper from '../../../../../modules/PageWrapper';
import {Filters} from './filters/Filters';
import {Table} from '../../../../../table/Table';
import {ColumnsFilter} from '../../../../../table/columnsFilter';
import {TrekerButton} from '../../../influence/bloggers/source/Treker/TrekerButton';
import {emptyDataColumns} from '../../../../../table/customizationTable';
import Search from './filters/Search';
import AddSourceWithoutTrafficId from './AddSourceWithoutTrafficId';

const Sources = () => {
  const [searchParams] = useSearchParams();
  const [prevTimeConfig, setPrevTimeConfig] = useState(null);
  const [firstRenderWrapper, setFirstRenderWrapper] = useState(false);

  useEffect(() => {
    const localStorageTimeConfig = localStorage.getItem('tracker/facebook/sources__Query');
    const params = new URLSearchParams(localStorageTimeConfig);
    const obj = {};

    for (const [key, value] of params) {
      if (key === 'time_config') {
        obj.time_config = value;
      } else if (key === 'time_start') {
        obj.time_start = !!value ? +value * 1000 : '';
      } else if (key === 'time_end') {
        obj.time_end = !!value ? +value * 1000 : '';
      }
    }

    if (!obj.time_config) return setFirstRenderWrapper(true);

    setPrevTimeConfig(obj);
    setFirstRenderWrapper(true);
  }, []);

  return (
    <PageWrapper
      firstTimeConfig='current_month'
      prevTimeConfig={prevTimeConfig}
      breadcrumbs={[
        {title: 'Трекер'},
        {isSeparator: true},
        {title: 'Facebook'},
        {isSeparator: true},
        {title: 'Потоки', path: '/tracker/facebook/sources', isActive: true},
      ]}
      title={'Потоки'}
    >
      <Component firstRenderWrapper={firstRenderWrapper} />
    </PageWrapper>
  );
};

const Component = observer(
  ({isVerify, setPreloader, timeConfig, firstRenderWrapper, CalendarForFullScreen}) => {
    const store = useStore();
    const navigate = useNavigate();
    const {search} = useLocation();
    const [themeMode, setThemeMode] = useState('');

    const abortControllerRef = useRef(null);

    const [data, setData] = useState({});
    const [rows, setRows] = useState([]);
    const [oldRows, setOldRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsFiltered, setColumnsFiltered] = useState([]);

    const [columnFilterList, setColumnFilterList] = useState({});
    const [showColumnsFilter, setShowColumnsFilter] = useState(false);
    const [activeColumns, setActiveColumns] = useState([]);
    const [firstRender, setFirstRender] = useState(false);
    const [openRowIdLoading, setOpenRowIdLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [isEmptyData, setIsEmptyData] = useState(false);
    const [romiList, setRomiList] = useState([]);

    const globalSort = useRef({sort: 'desc'});
    const globalID = useRef(0);

    useEffect(() => {
      if (!copied) {
        return;
      }

      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }, [copied]);

    const timeoutRef = useRef(null);

    useEffect(() => {
      const containersXL = document.querySelectorAll('.container-xxl');
      containersXL.forEach((el) => (el.style.maxWidth = '100%'));
      return () => {
        containersXL.forEach((el) => (el.style.maxWidth = ''));
        clearTimeout(timeoutRef.current);
      };
    }, []);

    useEffect(() => {
      const hideColumns =
        JSON.parse(localStorage.getItem('tracker/facebook/sources_filtered')) || [];
      if (hideColumns.length === 0) {
        localStorage.setItem(
          'tracker/facebook/sources_filtered',
          JSON.stringify([
            'click2reg',
            'click2cpa',
            'lead2fd',
            'lead2cpa',
            'chat2reg',
            'reg2cpa',
            'fd2cpa',
          ])
        );
      }
    }, []);

    useEffect(() => {
      if (isVerify && timeConfig.time_config && firstRenderWrapper) {
        timeoutRef.current = setTimeout(() => {
          getTable(localStorage.getItem('tracker/facebook/sources__Query') || search, 'firstData');
        }, 1000);
      }
    }, [isVerify, timeConfig, firstRenderWrapper]);

    const getTable = async (str = search, type = 'firstData') => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;

      let newString = str;
      newString = updateQueryString(newString, 'time_config', timeConfig.time_config);
      newString = updateQueryString(newString, 'time_start', timeConfig.time_start);
      newString = updateQueryString(newString, 'time_end', timeConfig.time_end);

      if (str.search('group') === -1) {
        newString = updateQueryString(newString, 'state', 'active,recently_created');
        newString = updateQueryString(newString, 'group', 'source,campaign,adset,ad');
      }

      navigate(newString);

      localStorage.setItem('tracker/facebook/sources__Query', newString);

      const fetchData = async (query) => {
        if (type === 'changeFilters' || type === 'firstData') setTableLoading(true);

        try {
          const req = await store.trafficsFacebookLinksGetStatistics(
            `${query.replace(/\?/g, '')}`,
            controller.signal
          );

          setIsEmptyData(req.rows.length === 0);
          const preColumns = createColumns(req.rows[0], req.filter, newString);
          const newColumns = req.rows.length
            ? preColumns
            : [preColumns[0], ...createColumns(emptyDataColumns)];
          setColumns(newColumns);
          setColumnsFiltered(newColumns);

          if (type === 'firstData') {
            setPreloader(false);
          }

          setRows(createRows(req.rows, req.filter));
          setOldRows(createRows(req.rows, req.filter));
          setTableLoading(false);

          return req;
        } catch (error) {
        } finally {
          if (abortControllerRef.current === controller) {
            abortControllerRef.current = null;
          }
        }
      };

      const obj = queryToObject(newString);

      if (typeof obj.group === 'string') {
        setData(await fetchData(newString));
      } else if (Array.isArray(obj.group)) {
        obj.group = `${obj.group[0]}`;
        const query = objectToQuery(obj);
        setData(await fetchData(query));
      } else {
        setData(await fetchData(newString));
      }
    };

    const updateQueryString = (str, key, value) => {
      const regex = new RegExp(`[?&]${key}=([^&]*)`);
      const match = str.match(regex);

      if (match) {
        if (match[1] === value) {
          return str;
        }
        return str.replace(regex, `${match[0][0]}${key}=${value}`);
      }
      return `${str}${str.includes('?') ? '&' : '?'}${key}=${value}`;
    };

    const queryToObject = (queryString) => {
      const params = new URLSearchParams(
        queryString.startsWith('?') ? queryString.slice(1) : queryString
      );
      const result = {};

      params.forEach((value, key) => {
        result[key] = value.includes(',') ? value.split(',') : value;
      });

      return result;
    };

    const objectToQuery = (params) => {
      const query = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          query.set(key, value.join(',')); // Якщо значення — масив, об'єднуємо його через кому
        } else if (value !== undefined && value !== null) {
          query.set(key, value); // Якщо значення є, додаємо його
        }
      });

      return `?${query.toString()}`;
    };

    const createColumns = (data, filters, str) => {
      if (!data) {
        return [{column: {name: filters?.group[0], label: filters?.group[0]}}];
      }
      const columnFilterList = transformObjectToBooleans(data);
      delete columnFilterList.id;
      delete columnFilterList.name;
      setColumnFilterList(columnFilterList);

      const columnsNames = !!data ? Object.entries(data).filter((item) => item[0] !== 'id') : [];
      const columnList = columnsNames.map((item, index) => {
        if (Object.getPrototypeOf(item[1] || '') !== Object.prototype) {
          const column = {
            name: item[0],
            label: item[0],
            sort: globalSort.current.name === item[0] ? globalSort.current.sort : 'desc',
            isExpandable: false,
          };

          if (
            !!filters &&
            !!filters?.group &&
            !!filters?.group.length &&
            !!item &&
            item[0] === 'name'
          ) {
            const searchObj = queryToObject(str);

            column.name = filters.group[0];
            column.label = filters.group[0];
            column.isExpandable =
              typeof searchObj.group !== 'string' &&
              searchObj.group[searchObj.group.length - 1] !== filters?.group[0]
                ? true
                : false;
          }
          return {
            column,
          };
        }

        return {
          column: {name: item[0], label: item[0]},
          subColumns: Object.keys(item[1]).map((key, index) => ({
            name: key,
            label: key,
            sort: globalSort.current.name === key ? globalSort.current.sort : 'desc',
            startIndex: index === 0,
            endIndex: Object.keys(item[1]).length - 1 === index,
          })),
        };
      });

      return columnList.filter((item) => item.column.label !== 'name');
    };

    const createRows = (data, filters) => {
      const newRows = JSON.parse(JSON.stringify(data))
        .sort((a, b) => {
          if (filters.group[0] === 'day') {
            return moment(b.name, 'DD.MM.YYYY').diff(moment(a.name, 'DD.MM.YYYY'));
          } else if (filters.group[0] === 'month') {
            return moment(b.name, 'MM.YYYY').diff(moment(a.name, 'MM.YYYY'));
          } else {
            return b.id - a.id;
          }
        })
        .map((item) => {
          globalID.current = globalID.current + 1;
          if (item.name === 'pwa') {
            item.name = 'Приложения';
            item.id = '';
          }
          if (item.name === 'telegram') {
            item.name = 'Схемы';
          }
          const row = {...item, isOpen: false, childrenCount: 0, globalID: globalID.current + 1};
          if (!!filters && !!filters.group && !!filters.group.length && !!filters?.group[0]) {
            row[filters?.group[0]] =
              filters?.group[0] === 'campaign' ||
              filters?.group[0] === 'adset' ||
              filters?.group[0] === 'ad'
                ? `${!!item.name ? item.name : 'Не известно'}`
                : `${item.id === 0 ? '' : '#' + item.id} ${
                    !!item.name ? item.name : 'Не известно'
                  }`;
            delete item.name;
          }
          return row;
        });

      return newRows;
    };

    const transformObjectToBooleans = (obj) => {
      if (typeof obj !== 'object' || obj === null) {
        return true;
      }
      if (Array.isArray(obj)) {
        return obj.map(transformObjectToBooleans);
      }
      const result = {};
      for (const key in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, key)) continue;
        result[key] = transformObjectToBooleans(obj[key]);
      }
      return result;
    };

    const onSort = (sort) => {
      setColumns((prev) => {
        return prev.map((item) => {
          if (!!item?.subColumns && item?.subColumns?.length > 0) {
            const sub = item.subColumns.map((sc) => {
              if (sc.name === sort.name) {
                return {...sc, sort: sort.sort};
              }
              return sc;
            });
            return {...item, subColumns: sub};
          } else {
            if (item.name === sort.name) {
              return {...item, sort: sort.sort};
            }

            return {...item, sort: sort.sort};
          }
        });
      });

      globalSort.current = sort;
      const baseRows = JSON.parse(JSON.stringify(rows)).sort((a, b) => {
        if (!!sort.group) {
          return sort.sort === 'asc'
            ? a[sort.group][sort.name] - b[sort.group][sort.name]
            : b[sort.group][sort.name] - a[sort.group][sort.name];
        } else if (typeof a[sort.name] === 'string' || typeof b[sort.name] === 'string') {
          return sort.sort !== 'asc' ? a.globalID - b.globalID : b.globalID - a.globalID;
        } else {
          return sort.sort === 'asc' ? a[sort.name] - b[sort.name] : b[sort.name] - a[sort.name];
        }
      });

      const groups = {};
      const noLinkedArr = [];

      // Розподіл рядків на групи та батьківські елементи
      baseRows.forEach((row) => {
        if (!!row.parentId) {
          groups[row.parentId] = groups[row.parentId] || [];
          groups[row.parentId].push(row);
        } else {
          noLinkedArr.push(row);
        }
      });

      // Вставка знайдених груп у масив `noLinkedArr`
      const insertEntries = (entries, targetArray) => {
        const notFoundEntries = [];

        entries.forEach(([key, group]) => {
          const findIndex = targetArray.findIndex((item) => `${item.id}_${item.name}` === key);
          if (findIndex === -1) {
            notFoundEntries.push([key, group]);
          } else {
            targetArray.splice(findIndex + 1, 0, ...group);
          }
        });

        return notFoundEntries;
      };

      // Основний цикл для пошуку та вставки
      let notFoundEntries = insertEntries(Object.entries(groups), noLinkedArr);

      while (notFoundEntries.length > 0) {
        notFoundEntries = insertEntries(notFoundEntries, noLinkedArr);
      }

      // Оновлення стану
      setRows(noLinkedArr);
    };

    const open = async (id, name, colIndex, rowIndex, colName, globalID, nextColumn, row) => {
      const newColumns = JSON.parse(JSON.stringify(columns));
      const newRows = JSON.parse(JSON.stringify(rows));

      const string = localStorage.getItem('tracker/facebook/sources__Query');
      const params = new URLSearchParams(string);
      const groupValues = params.get('group').split(',');
      const findColumnName = groupValues.findIndex((item) => item === colName);

      const findDistanceBetweenObjects = (arr, keyName, id, name) => {
        const firstObjectIndex = arr.findIndex((obj) => obj.globalID === id && obj.name === name);
        if (firstObjectIndex === -1) {
          return console.log("Перший об'єкт не знайдено");
        }
        // Шукаємо індекс наступного об'єкта, який має той самий ключ
        const secondObjectIndex = arr.findIndex(
          (obj, index) => index > firstObjectIndex && keyName in obj
        );

        if (secondObjectIndex === -1) {
          groupValues.splice(0, findColumnName + 1);

          const filteredRows = newRows.filter((item, index) => {
            if (index > rowIndex) {
              return groupValues.some(
                (val) => item[val] !== undefined && item[val] !== null && item[val] !== NaN
              );
            } else {
              return false;
            }
          });
          return filteredRows.length;
        }

        // Рахуємо кількість об'єктів між першим і другим
        groupValues.splice(findColumnName);

        const filteredRows = newRows.filter((item, index) => {
          if (index > firstObjectIndex && index < secondObjectIndex) {
            return groupValues.some(
              (val) => item[val] !== undefined && item[val] !== null && item[val] !== NaN
            );
          } else {
            return false;
          }
        });

        const distance = secondObjectIndex - firstObjectIndex - 1 - filteredRows.length;
        return distance;
      };

      if (newRows[rowIndex].isOpen) {
        const findDistanceIndex = findDistanceBetweenObjects(newRows, colName, globalID, name);
        const filteredRows = newRows.filter((row, index) => {
          if (index < rowIndex) {
            return true;
          } else if (index > rowIndex && index < findDistanceIndex + rowIndex + 1) {
            return false;
          } else {
            return true;
          }
        });

        filteredRows[rowIndex].isOpen = false;

        const keys = filteredRows.map((key) => Object.keys(key));
        const uniqueValues = [...new Set(keys.flat())];

        const filteredColumns = newColumns.filter((col) => {
          if (uniqueValues.includes(col.column.name)) {
            return true;
          } else {
            return false;
          }
        });

        setColumns(filteredColumns);
        setColumnsFiltered(filteredColumns);
        setRows(filteredRows);
        return;
      }

      const obj = queryToObject(search);
      const groupsArr = [...obj.group];
      groupsArr.splice(colIndex + 2);
      const [removedValue] = groupsArr.splice(groupsArr.length - 1, 1);
      obj.group = removedValue;
      groupsArr.forEach((item, index) => {
        if (index === groupsArr.length - 1) {
          if (item === 'pwa') {
            obj[`app_id`] = id;
          } else if (item === 'source') {
            obj[`link_id`] = id;
          } else if (item === 'app_type') {
            obj[`app_type`] = id;
          } else if (item === 'traffic_type') {
            obj[`traffic_type`] = id;
          } else {
            obj[`${item}_id`] = id;
          }
        }
      });

      if (!!row?.year_date) {
        obj.year_id = row.year_date;
      }
      if (!!row?.month_date) {
        obj.month_id = row.month_date;
      }
      if (!!row?.day_date) {
        obj.day_id = row.day_date;
      }

      const query = objectToQuery(obj);

      setOpenRowIdLoading(id);
      const req = await store.trafficsFacebookLinksGetStatistics(`${query}`.replace(/\?/g, ''));
      setOpenRowIdLoading(undefined);

      const getColumns = createColumns(req.rows[0], req.filter, search);
      const getRows = createRows(req.rows, req.filter).map((item) => {
        return {
          ...item,
          isChildren: true,
          parentId: `${id}_${name}`,
          col: colIndex + 1,
        };
      });

      newColumns.splice(colIndex + 1, 0, getColumns[0]);

      const seen = new Set();

      const col = newColumns.filter((item) => {
        const name = item.column.name; // Унікальність визначаємо за `column.name`
        if (seen.has(name)) {
          return false; // Пропускаємо дублікати
        }
        seen.add(name); // Додаємо до Set
        return true;
      });

      newRows[rowIndex].isOpen = true;
      newRows[rowIndex].childrenCount = getRows.length;
      newRows.splice(rowIndex + 1, 0, ...getRows);

      setRows(newRows);

      if (getRows.length === 0) return;

      setColumns(col);
      setColumnsFiltered(col);
    };

    useEffect(() => {
      if (firstRender) return;
      const itemsS = localStorage.getItem('tracker/facebook/sources_filtered');
      if (itemsS) {
        setActiveColumns(JSON.parse(itemsS) || []);
      }
      setFirstRender(true);
    }, [columns, firstRender]);

    useEffect(() => {
      const newColumns = columns
        .map((it) => {
          if (!!it?.subColumns && it?.subColumns?.length > 0) {
            const newSubColumns = [...it.subColumns].filter(
              (sub) => !activeColumns.includes(sub.name)
            );
            return {...it, subColumns: newSubColumns};
          }
          return it;
        })
        .filter((item) => !activeColumns.includes(item?.column.name))
        .filter((item) => item?.subColumns?.length !== 0);

      setColumnsFiltered(newColumns);
    }, [activeColumns, columns]);

    return (
      <div
        className={clsx(css.container, fullScreen && css.container_fullScreen)}
        style={{'--bgFullScreen': themeMode === 'dark' && !!fullScreen ? '#0f1015' : '#F9F9F9'}}
      >
        <div className='d-flex justify-content-between align-items-end' id='facebook_table_filters'>
          <Filters
            isVerify={isVerify}
            getTable={getTable}
            setPreloader={setPreloader}
            search={search}
            setThemeMode={setThemeMode}
            resultsCount={rows?.filter((it) => !it?.isChildren)?.length ?? 0}
            CalendarForFullScreen={CalendarForFullScreen}
            fullScreen={fullScreen}
            AddSource={
              <>
                <AddSourceWithoutTrafficId
                  getInfluence={getTable}
                  romiList={romiList}
                  timeConfig={timeConfig}
                />
              </>
            }
            actions={
              <>
                <div>
                  <ColumnsFilter
                    linkStore={'tracker/facebook/sources_filtered'}
                    data={columnFilterList}
                    activeColumns={activeColumns}
                    setActiveColumns={setActiveColumns}
                    defaultLinkStore={[
                      'click2reg',
                      'click2cpa',
                      'lead2fd',
                      'lead2cpa',
                      'chat2reg',
                      'reg2cpa',
                      'fd2cpa',
                    ]}
                  />
                </div>
                <div>
                  <TrekerButton
                    onClick={() => setFullScreen((prev) => !prev)}
                    style={{gap: '0px'}}
                    svg={
                      <>
                        {!fullScreen && (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            enableBackground='new 0 0 48 48'
                            width='15'
                            height='16'
                            id='Layer_4'
                            version='1.1'
                            viewBox='0 0 48 48'
                            xmlSpace='preserve'
                          >
                            <g>
                              <polygon points='20.539,16.826 9.877,6.164 16.02,0 0,0 0,16.074 6.222,9.831 16.877,20.486  ' />
                              <polygon points='27.462,16.826 38.123,6.164 31.981,0 48,0 48,16.074 41.778,9.831 31.123,20.486  ' />
                              <polygon points='27.462,31.176 38.123,41.838 31.981,48.002 48,48.002 48,31.927 41.778,38.17 31.123,27.516  ' />
                              <polygon points='20.539,31.176 9.878,41.838 16.02,48.002 0.001,48.002 0.001,31.927 6.223,38.17 16.877,27.516     ' />
                            </g>
                          </svg>
                        )}
                        {!!fullScreen && (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            enableBackground='new 0 0 48 48'
                            width='15'
                            height='16'
                            id='Layer_4'
                            version='1.1'
                            viewBox='0 0 48 48'
                            xmlSpace='preserve'
                          >
                            <g>
                              <polygon points='0,44.34 10.662,33.678 4.52,27.514 20.539,27.514 20.539,43.588 14.316,37.345 3.662,48  ' />
                              <polygon points='47.999,44.34 37.338,33.678 43.479,27.514 27.461,27.514 27.461,43.588 33.683,37.345 44.338,48     ' />
                              <polygon points='47.999,3.66 37.338,14.322 43.479,20.486 27.461,20.486 27.461,4.411 33.683,10.654 44.338,0  ' />
                              <polygon points='0.001,3.66 10.662,14.322 4.521,20.486 20.539,20.486 20.539,4.411 14.317,10.654 3.663,0  ' />
                            </g>
                          </svg>
                        )}
                      </>
                    }
                  ></TrekerButton>
                </div>
              </>
            }
            inputSearch={
              <Search
                title='Поиск...'
                rows={oldRows}
                setRows={setRows}
                setColumns={setColumns}
                columns={columns}
                searchParameters={['source']}
              />
            }
          />
        </div>
        {columns.length > 0 && (
          <Table
            globalSort={globalSort.current}
            columns={columnsFiltered}
            rows={rows}
            open={open}
            onSort={onSort}
            openRowIdLoading={openRowIdLoading}
            tableLoading={tableLoading}
            isEmptyData={isEmptyData}
            fullMode={fullScreen}
            timeConfig={timeConfig}
            sortLink={'/sources/list/sort'}
          />
        )}
      </div>
    );
  }
);

export default Sources;
